
export default {
  components: {
    hotHero: () => import('~/components/Organisms/Hero/Hero.vue'),
    OrganicModal: () => import('~/components/Molecules/Organic/Modal/Modal.vue'),
    hotContentBlocks: () => import('~/components/Organisms/ContentBlocks/ContentBlocks.vue'),
    hotSocialProofProduct: () => import('~/components/Organisms/SocialProofProduct/SocialProofProduct.vue'),
    hotPaymentsCarousel: () => import('~/components/Organisms/PaymentsCarousel/PaymentsCarousel.vue'),
    hotAccordionToTab: () => import('~/components/Organisms/AccordionToTab/AccordionToTab.vue'),
    hotFaq: () => import('~/components/Organisms/Faq/Faq.vue'),
    hotBanner: () => import('~/components/Molecules/Banner/Banner.vue'),
  },

  computed: {
    isPortuguese() {
      return Boolean(['pt-br'].includes(this.$i18n.locale))
    },
  },
}
