
import Provider from '@/services/provider'
import gtm from '~/mixins/gtm.js'
import payments from '~/components/Templates/Payments/Payments.vue'

export default {
  mixins: [gtm],
  nuxtI18n: {
    parsePages: true,
    locales: ['pt-br', 'en', 'es', 'es-mx', 'es-co', 'es-es', 'fr'],
  },

  components: {
    payments,
  },

  data() {
    return {
      // GTM
      gtmSubsection: 'Payments',
      gtmSubsection2: '',
      // SEO
      title: this.$t('seo.payments.title'),
      description: this.$t('seo.payments.description'),
      url: `${process.env.BASE_URL}${this.$route.path}`,
      locale: this.$i18n.locales.find(l => l.code === this.$i18n.locale).iso,
      routeName: this.$route.name,
      orgSchema: { description: this.$t('seo.payments.description'), ...this.$t('seo.organization') },
    }
  },

  head() {
    const basePayload = {
      title: this.title,
      description: this.description,
      url: this.url,
      locale: this.locale,
      routeName: this.routeName,
    }

    return {
      ...Provider.head.useComposeHeadTag({
        ...basePayload,
        schema: [
          { ...Provider.head.setSchemaOrganization({ ...this.orgSchema }) },
          { ...Provider.head.setSchemaWebpage({ ...basePayload }) },
          { ...Provider.head.setSchemaBreadcrumb({ breadcrumbs: this.breadcrumbs }) },
        ],
        meta: {},
        script: [],
        link: [],
      }),
    }
  },

  computed: {
    breadcrumbs() {
      const payload = {
        routePath: this.$route.path,
        dinamicValue: false,
      }

      const list = Provider.breadcrumbs.getItemList(payload)

      return list
    },
  },
}
